import '@aws-amplify/ui-react/styles.css';
import {
    Datagrid,
    TextField,
    SimpleForm,
    Create,
    Show,useShowContext,useUpdate,
    SimpleShowLayout,
    DateField, TabbedForm, Toolbar, SaveButton,
    ShowButton, List, BooleanField, BooleanInput, useListContext,
    ReferenceField, FileInput, FileField,
    useStore, WrapperField, useRedirect, useNotify,
    ReferenceManyField, useRecordContext, useGetList, ListContextProvider, Pagination,
    CreateButton, FunctionField, SingleFieldList, ChipField, TextInput, TopToolbar, NumberInput, NumberField, ReferenceInput, SelectInput, Edit, EditButton, useGetOne, RichTextField, usePermissions
} from "react-admin";
import { TrueIcon, FalseIcon } from './icons';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Card, FormLabel, Button, Box, Select, MenuItem, Typography, Grid, Chip, useMediaQuery, Popover } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { RotationFilterForm } from "./RotationFilterForm";
import { RotationPreferencesList } from './Dashboard';
import {  useEffect, useState } from 'react';
import { DataGridWithIndex } from './DataGridWithIndex'
import { RichTextInput } from 'ra-input-rich-text';
import { BackButton } from './BackButton';
import { is_preference_white_text, get_color_code, ordinal_suffix_of, wordy_ordinal } from './variable_preferences';
import { indexToYear } from './Rotation'
import StarIcon from '@mui/icons-material/Stars';

export const TraineePreferencesList = ({ priority, links }) => {
    const record = useRecordContext();
    if (record.priority === priority) {
        return (
            <ReferenceField source="departmentID" reference="departments" label="Department" link={links} sx={{ '& .MuiChip-label': { color: 'black' } }} >
                <FunctionField render={record => { return (<Chip label={<DepartmentPlusSiteField record={record} />} />) }} />
            </ReferenceField>
        )
    }
}

export const ManualOverrideField = (props) => {

    const redirect = useRedirect();
    const traineeRecord = useRecordContext();

    if (traineeRecord?.manualOverrideID) {
        return (
            <ReferenceField source="manualOverrideID" reference="departments" link={(record, resource) => `/trainees/${traineeRecord.id}`} sx={{ '& .MuiChip-label': { color: 'black' } }} >
                <FunctionField render={record => { return (<Chip label={<DepartmentPlusSiteField record={record} />} />) }} />
            </ReferenceField>
        )
    } else {
        return (
            <Button onClick={(e) => { e.stopPropagation(); redirect('edit', 'trainees', traineeRecord.id); }}>
                <AddIcon />
            </Button>
        )
    }
}

export const EditTickField = (props) => {

    const redirect = useRedirect();
    const traineeRecord = useRecordContext();

    return (
        <Button onClick={(e) => {
            e.stopPropagation(); redirect('edit', 'trainees', traineeRecord.id, {}, { tick: props?.tick });
        }}>
            Edit
        </Button >
    )

}

export const EditSupervisorField = (props) => {

    const redirect = useRedirect();
    const traineeRecord = useRecordContext();

    return (
        <Button onClick={(e) => {
            e.stopPropagation(); redirect('edit', 'trainees', traineeRecord.id, {}, { supervisor: props?.supervisor });
        }}>
            Edit
        </Button >
    )

}

export const EditHRComments = (props) => {

    const redirect = useRedirect();
    const traineeRecord = useRecordContext();

    return (
        <Button onClick={(e) => {
            e.stopPropagation(); redirect('edit', 'trainees', traineeRecord.id, {}, { hrComments: true });
        }}>
            Edit
        </Button >
    )

}

export const NamePlusReview = ({show_review, record}) => {
    if (show_review != null) {
        return DepartmentPlusSiteField({record}) + " " + "★".repeat(show_review) + "☆".repeat(3-show_review)
    } else {
        return <DepartmentPlusSiteField record={record} />
    }
} 


export const SeatChipField = ({ priority, text_color,nontransparentDepartmentID,nontransparentGroupID, show_review }) => {
    // TODO: make nontransaparent if nontransparentGroupID == groupID
    const record = useRecordContext();
    if (nontransparentDepartmentID) {
        if (record.id == nontransparentDepartmentID) {
            return (
                <Chip label={<NamePlusReview show_review={show_review} record={record}/>} sx={{ backgroundColor: get_color_code(priority), '& .MuiChip-label': { color: text_color } }} />
            )
        } else {
            return (
                <Chip label={<NamePlusReview show_review={show_review} record={record}/>} sx={{ backgroundColor: get_color_code(priority), opacity:0.2, '& .MuiChip-label': { color: text_color } }} />
            )
        }
    }
    if (nontransparentGroupID) {
        if (record.groupID == nontransparentGroupID) {
            return (
                <Chip label={<NamePlusReview show_review={show_review} record={record}/>} sx={{ backgroundColor: get_color_code(priority), '& .MuiChip-label': { color: text_color } }} />
            )
        } else {
            return (
                <Chip label={<NamePlusReview show_review={show_review} record={record}/>} sx={{ backgroundColor: get_color_code(priority), opacity:0.2, '& .MuiChip-label': { color: text_color } }} />
            )
        }
    }
    return (
        <Chip label={<NamePlusReview show_review={show_review} record={record}/>} sx={{ backgroundColor: get_color_code(priority), '& .MuiChip-label': { color: text_color } }} />
    )
}


export const SeatField = (props) => {
    const { nontransparentDepartmentID,nontransparentGroupID, show_review } = props
    const record = useRecordContext();
    // console.log('seat', record)
    var priority = record['preferencePriority']
    if (priority == 0) {
        priority = parseInt(process.env.REACT_APP_NUM_OF_PREFRENCES) + 1
    }
    if (priority != null) {
        if (priority > process.env.REACT_APP_NUM_OF_PREFRENCES) {
            priority = parseInt(process.env.REACT_APP_NUM_OF_PREFRENCES) + 1
        }
        const text_color = is_preference_white_text(priority) ? 'white' : 'black'

        return (
            <ReferenceField source="departmentID" reference="departments" label="Department" sx={{ '& .MuiChip-label': { color: 'black' } }} link={!props?.nolink && "show"}  >
                <SeatChipField nontransparentDepartmentID={nontransparentDepartmentID} nontransparentGroupID={nontransparentGroupID} show_review={record.review} priority={priority} text_color={text_color}/>
            </ReferenceField>
        )
    } else {
        return (
            <ReferenceField source="departmentID" reference="departments" label="Department" sx={{ '& .MuiChip-label': { color: 'black' } }} link={!props?.nolink && "show"} >
                <FunctionField render={record => { return (<Chip label={<DepartmentPlusSiteField record={record} />} />) }} />
            </ReferenceField>
        )
    }
}

export const TraineeShowPageLink = () => {
    const trainee = useRecordContext();
    return (
        <Link style={{ textDecoration: 'none', color: '#1976d2' }} to={'/trainees/' + trainee.id + '/show'}>{trainee.name}</Link>
    )
}

const MouseOverPopover = ({msg, msg_summary}) => {
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
  
    return (
      <div>
        <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {msg_summary}
        </Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}><RichTextField source="comment" /></Typography>
        </Popover>
      </div>
    );
  }

export const TraineeCommentsField = (props) => {
    const record = useRecordContext(props);
    const comment = record?.comment?.replace(/<[^>]*>/g, '').replace(/\\par[d]?/g, "").replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, "").replace(/\\'[0-9a-zA-Z]{2}/g, "").trim()
    return record ? <MouseOverPopover msg={comment} msg_summary={comment?.slice(0,10)}/> : null;
}


export const TraineeList = (props) => {

    const rotationsList = useGetList('rotations');
    
    const [currentCohorts, setCurrentCohorts] = useStore('trainee.currentCohorts', rotationsList.data?.filter((rotation) => (rotation.cohortsIndex <= 11 && rotation.visible)))
    const [rotationID, setFilter] = useStore('trainee.filter');
    const [siteID, setSiteID ] = useStore('trainee.site', "");
    useEffect(() => {
        var rotations = rotationsList.data
        rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))
        const currentRotation = rotations?.at(-1)
        if (currentRotation?.id != rotationID) {
            setFilter(currentRotation?.id)
        }
    })

    const [page, setPage] = useState( 1);
    const [perPage, setPerPage] = useStore('trainee.perPage', 1000);
    const sort = { field: 'name', order: 'ASC' };
    var filter = {  or: currentCohorts?.map((rotation) => ({ cohortID: { eq: rotation.id } })) }
    if (siteID ) {
     filter = { siteID: {eq: siteID}, or: currentCohorts?.map((rotation) => ({ cohortID: { eq: rotation.id } })) }
    }
    console.log(siteID, currentCohorts?.length)
    const tempPerPage = (siteID  || currentCohorts?.length <= 2) ? 1000 : perPage 
    const tempPage = (siteID  || currentCohorts?.length <= 2) ? 1: page  
    console.log(page, perPage)
    console.log(tempPage, tempPerPage)

    var { data, pageInfo, isLoading } = useGetList('trainees', {
        filter: { traineesByTypeAndTraineeIndex: { type: "Trainee",  filter:filter } },
        pagination: { page: tempPage, perPage: tempPerPage },
        sort,
    });

    if (isLoading) { return (<></>) }
    const { hasNextPage, hasPreviousPage } = pageInfo
    // const { hasNextPage, hasPreviousPage } = pageInfo;
    const filterValues = { preferencesByTraineeIDAndRotationID: { rotationID: { "eq": rotationID } } };
    const seatsFilterValues = { seatsByTraineeIDAndRotationID: { rotationID: { "eq": rotationID } } };
    const publishedSeatsFilterValues = { publishedSeatsByTraineeIDAndRotationID: { rotationID: { "eq": rotationID } } };
    const setFilters = filters => setFilter(filters.rotation);
    var preferenceColumns = []
    for (let priority = 0; priority < process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {

        preferenceColumns.push(<ReferenceManyField label={ordinal_suffix_of(priority + 1) + " Preferences"} reference="preferences" target="preferencesByTraineeIDAndRotationID.traineeID.rotationID" filter={filterValues}>
            <SingleFieldList sx={{gap:0}}>
                <TraineePreferencesList priority={priority + 1} links={false} />
            </SingleFieldList>
        </ReferenceManyField>)
    }
    return (
        <ListContextProvider value={{ data, page, perPage, setPage, setPerPage, hasNextPage, hasPreviousPage, filterValues, setFilters, sort }} >

            <RotationFilterForm  {...{ rotationID,siteID, setSiteID,currentCohorts, setCurrentCohorts, isLoading }} />
            <div>
                <Card>
                    <DataGridWithIndex sort={sort} sx={{
                        '& .column-id': { textAlign: 'left' },
                        '& .column-name': { textAlign: 'left' },
                    }} >
                        <WrapperField source="name">
                            <TraineeShowPageLink />
                        </WrapperField>
                        {process.env.REACT_APP_MULTISITE == "1" &&
                            <ReferenceField source="siteID" reference="sites" label="Office" link={false}>
                                <TextField source="name" />
                            </ReferenceField>}
                        <ReferenceField source="cohortID" reference="rotations" label="Cohort" link={false}>
                            <FunctionField label="Cohort" render={(record) => indexToYear(record.cohortsIndex)} />
                        </ReferenceField>
                        {preferenceColumns}
                        {/* <NewPreferenceButton rotationID={filter} /> */}
                        <ManualOverrideField {...props} label="Manual Override" />
                        <WrapperField label="Comments">
                        <ListCommentsField rotationID={rotationID}/>
                        
                        </WrapperField>
                        <ReferenceManyField label="Assigned Seats" reference='seats' target='seatsByTraineeIDAndRotationID.traineeID.rotationID' filter={seatsFilterValues}>
                            <SingleFieldList linkType={false}>
                                <SeatField />
                            </SingleFieldList>
                        </ReferenceManyField>
                        {/* <ReferenceManyField label="Published Seats" reference='publishedSeats' target='publishedSeatsByTraineeIDAndRotationID.traineeID.rotationID' filter={publishedSeatsFilterValues}>
                            <SingleFieldList  label="Published Seats" linkType="show">
                                <SeatField />
                            </SingleFieldList>
                        </ReferenceManyField> */}
                    </DataGridWithIndex>
                </Card>
                {/* <PostPagination /> */}
            </div>
        </ListContextProvider>
    );
};

export const ListCommentsField = ({rotationID}) => {

    const commentsFilterValues = { traineeCommentsByTraineeIDAndRotationID: { rotationID: { "eq": rotationID} } };
    return (<ReferenceManyField  reference='traineeComments' target="traineeCommentsByTraineeIDAndRotationID.traineeID.rotationID" filter={commentsFilterValues} >
    <SingleFieldList linkType={false}>
       <TraineeCommentsField />
    </SingleFieldList>
</ReferenceManyField>)
}

export const TraineeCreate = (props) => {
    const { permissions } = usePermissions();
    return (
        <Create {...props} actions={<BackButton />}>
            <TabbedForm defaultValues={{ type: "Trainee" }}>
                <TabbedForm.Tab label="single">
                    <TextInput source="email" />
                    <TextInput source="name" />
                    <ReferenceInput source="cohort" reference="rotations" />
                    <ReferenceInput source="site" reference="sites" />
                </TabbedForm.Tab>
                {permissions?.includes("SuperAdmins") &&
                    <TabbedForm.Tab label="batch">
                        <FormLabel >
                            Upload a .csv file with headers "Name" and "Email". <br />

                            Can also add headers with format "2023-05-23:First Preference", "2023-05-23:Second Preference" and "2023-05-23:Third Preference" with the date replaced by the start date of the required rotation

                        </FormLabel>
                        <FileInput source="attachments">
                            <FileField source="src" title="title" />
                        </FileInput>
                    </TabbedForm.Tab>}
            </TabbedForm>
        </Create>)
};

export const NewPreferenceButton = ({ rotationID, traineeRecord }) => {
    // console.log("rotationID", rotationID)
    // console.log("traineeID", traineeRecord)
    var traineeID = traineeRecord?.id?.split("::")[0]
    const record = useRecordContext();
    if (rotationID && !traineeID) {
        traineeID = record.id
        traineeRecord = record
    }
    if (traineeID && !rotationID) {
        rotationID = record.id
    }
    let counter = 0;
    for (let preferenceIndex in traineeRecord.Preferences.items) {
        if (traineeRecord.Preferences.items[preferenceIndex].traineeID == traineeID && traineeRecord.Preferences.items[preferenceIndex].rotationID == rotationID) {
            counter += 1;
        }
    }

    return (<CreateButton label="Add Preferences" resource="preferences" state={{ record: { traineeID: traineeID, rotationID: rotationID } }} disabled={counter >= 1} />)

}
const TraineeShowNested = (props) => {
    const {record}  = useShowContext();
    const [update, { isLoading, error }] = useUpdate();

    const publishSeatSupervisors = () => {
        console.log(record)
        const diff = {id: record.id}
        if (record?.firstSeatSupervisorHR) {
            diff["firstSeatSupervisor"] = record?.firstSeatSupervisorHR
        }
        if (record?.secondSeatSupervisorHR) {
            diff["secondSeatSupervisor"] = record?.secondSeatSupervisorHR
        }
        if (record?.thirdSeatSupervisorHR) {
            diff["thirdSeatSupervisor"] = record?.thirdSeatSupervisorHR
        }
        if (record?.fourthSeatSupervisorHR) {
            diff["fourthSeatSupervisor"] = record?.fourthSeatSupervisorHR
        }
        console.log(Object.keys(diff).length)
        if (Object.keys(diff).length> 0) {
            console.log("test")
            update(
                'trainees',
                { id: record.id, data: diff, previousData: record, meta: {admin: true} }
            )
        }
    };

    const {permissions} = usePermissions();
    return (
        <>
            <SimpleShowLayout>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SimpleShowLayout>
                            <TextField source="name" />
                            {process.env.REACT_APP_MULTISITE == "1" &&
                                <ReferenceField source="siteID" reference="sites" label="Site" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>}
                            <ReferenceField source="cohortID" reference="rotations" label="Training Contract Start Date" link={false} />

                            <ManualOverrideField {...props} label="Manual override for current rotation" />
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={6}>
                        <SimpleShowLayout>
                            <TextField source="email" label="Username" />
                            {process.env.REACT_APP_DESIRED_GRADUATION_SEAT_ON != "0" && <ReferenceField label="Desired Qualification Indicator" source="desiredGraduatingSeatID" reference='departments' >
                                <TextField source='name' />
                            </ReferenceField>}
                            <Grid container >
                                {process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME != "0" &&
                                    <Grid item xs={6}>
                                        <SimpleShowLayout sx={{ padding: 0 }}>
                                            <WrapperField label={process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME + " Seat Completed"}>
                                                <BooleanField source="litigationSeatCompleted" TrueIcon={TrueIcon} FalseIcon={FalseIcon}/>
                                                <EditTickField tick="litigation" />
                                            </WrapperField>
                                        </SimpleShowLayout>
                                    </Grid>}
                                {process.env.REACT_APP_BANKING_DEPARTMENT_NAME != "0" &&
                                    <Grid item xs={6}>
                                        <SimpleShowLayout sx={{ padding: 0 }}>
                                            <WrapperField label={process.env.REACT_APP_BANKING_DEPARTMENT_NAME + " Seat Completed"}>
                                                <BooleanField source="bankingSeatCompleted" TrueIcon={TrueIcon} FalseIcon={FalseIcon}/>
                                                <EditTickField tick="banking" />
                                            </WrapperField>
                                        </SimpleShowLayout>
                                    </Grid>}
                            </Grid>
                        </SimpleShowLayout>
                    </Grid>
                </Grid >
                <RotationPreferencesList traineeRecord={false} links={(record, resource) => `/departments/${record.id}/show`} />
                <Grid container >
                    <Grid item xs={3}>
                        <SimpleShowLayout sx={{ padding: 0 }}>
                            <WrapperField label="First Seat Supervisor">
                                <TextField source="firstSeatSupervisorHR" />
                                <EditSupervisorField supervisor={1} />
                            </WrapperField>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={3}>
                        <SimpleShowLayout sx={{ padding: 0 }}>
                            <WrapperField label="Second Seat Supervisor">
                                <TextField source="secondSeatSupervisorHR" />
                                <EditSupervisorField supervisor={2} />
                            </WrapperField>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={3}>
                        <SimpleShowLayout sx={{ padding: 0 }}>
                            <WrapperField label="Third Seat Supervisor">
                                <TextField source="thirdSeatSupervisorHR" />
                                <EditSupervisorField supervisor={3} />
                            </WrapperField>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={3}>
                        <SimpleShowLayout sx={{ padding: 0 }}>
                            <WrapperField label="Fourth Seat Supervisor">
                                <TextField source="fourthSeatSupervisorHR" />
                                <EditSupervisorField supervisor={4} />
                            </WrapperField>
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={3}>
                        <Button onClick={publishSeatSupervisors}>Publish seat supervisors</Button>
                    </Grid>
                </Grid>
                {/* <RichTextField source="traineeDiary" label="Trainee's Comments" /> */}
                <SimpleShowLayout sx={{ padding: 0 }}>
                    <WrapperField label="Graduate Development Team Comments">
                        <RichTextField source="hrComments" label="Graduate Development Team Comments" />
                        <EditHRComments />
                    </WrapperField>
                </SimpleShowLayout>
                {permissions?.includes("SuperAdmins") &&<SuperAdminEditButton/> }
            </SimpleShowLayout >
            </>)
}

export const TraineeShow = (props) => {    
    return (        <Show actions={<BackButton />} queryOptions={{ meta: { admin: true } }}>
        <TraineeShowNested/>
                </Show >

    )
};
export const SuperAdminEditButton = (props) => {
    const redirect = useRedirect();

    const traineeRecord = useRecordContext(props);
    return (<Button onClick={(e) => {
                        e.stopPropagation(); redirect('edit', 'trainees', traineeRecord.id, {}, { superAdmin: true });
                    }}>
                        SuperAdmin Edit
                    </Button >)
}

export const TraineeEditToolbar = () => {

    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>)
};

export const TraineeEdit = (props) => {
    const navigate = useNavigate();
    const notify = useNotify();
    const location = useLocation();
    const {permissions} = usePermissions();
    const onSuccess = () => {
        notify("Successfully updated");
        navigate(-1);
    };
    if (location?.state?.tick == "litigation") {
        return (
            <Edit {...props} transform={value => { return value; }} actions={<BackButton />} mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
                <SimpleForm toolbar={<TraineeEditToolbar />} sanitizeEmptyValues={false}>
                    <BooleanInput source="litigationSeatCompleted" label={process.env.REACT_APP_LITIGATION_DEPARTMENT_NAME + " Seat Completed"} />
                </SimpleForm>
            </Edit>)
    }
    if (location?.state?.tick == "banking") {
        return (
            <Edit {...props} transform={value => { return value; }} actions={<BackButton />} mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
                <SimpleForm toolbar={<TraineeEditToolbar />} sanitizeEmptyValues={false}>
                    <BooleanInput source="bankingSeatCompleted" label={process.env.REACT_APP_BANKING_DEPARTMENT_NAME + " Seat Completed"} />
                </SimpleForm>
            </Edit>)
    }
    if (location?.state?.supervisor > 0) {
        console.log(location?.state)

        return (
            <Edit {...props} transform={value => { return value; }} actions={<BackButton />} mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
                <SimpleForm toolbar={<TraineeEditToolbar />} sanitizeEmptyValues={false}>
                    <TextInput source={wordy_ordinal(location?.state?.supervisor) + "SeatSupervisorHR"} parse={value => value} label={"Supervisor"} />
                </SimpleForm>
            </Edit>)
    }
    if (location?.state?.hrComments) {
        return (
            <Edit {...props} transform={value => { return value; }} actions={<BackButton />} queryOptions={{ meta: { admin: true } }} mutationMode="pessimistic" mutationOptions={{ onSuccess, meta: { admin: true } }}>
                <SimpleForm toolbar={<TraineeEditToolbar />} sanitizeEmptyValues={false}>
                    <RichTextInput source="hrComments" label="Graduate Development Team Comments" parse={value => value} />
                </SimpleForm>
            </Edit>)
    }
    if (location?.state?.superAdmin && permissions?.includes("SuperAdmins")){
        return (<Edit {...props} transform={value => { return value; }} actions={<BackButton />} mutationMode="pessimistic" queryOptions={{ meta: { admin: true } }} mutationOptions={{ onSuccess, meta: { admin: true } }}>
            <SimpleForm toolbar={<TraineeEditToolbar />} sanitizeEmptyValues={false}>
                {/* <ReferenceInput source="manualOverrideID" reference='departments' allowEmpty filter={{ departmentsByTypeAndName: { type: "Department" } }} perPage={100}>
                    <SelectInput source="name" label="Manual Override Department" sx={{ width: "200px" }} allowEmpty parse={value => { return value; }} optionText={record => <DepartmentPlusSiteField record={record} />} />
                </ReferenceInput> */}
                <TextInput source="name"/>
                <ReferenceInput source="cohortID" reference='rotations' allowEmpty perPage={100}>
                    <SelectInput source="startDate" label="Cohort" sx={{ width: "200px" }}  />
                </ReferenceInput>
                <ReferenceInput source="siteID" reference='sites' allowEmpty perPage={100}>
                    <SelectInput source="name" label="Site" sx={{ width: "200px" }}  />
                </ReferenceInput>
                <RichTextInput source="traineeDiary" parse={value => { return value; }}/>
                <TextInput source="firstSeatSupervisor"sx={{ width: "300px" }}parse={value => { return value; }}/>
                <TextInput source="firstSeatSupervisorHR" sx={{ width: "300px" }}parse={value => { return value; }}/>
                <TextInput source="secondSeatSupervisor"sx={{ width: "300px" }} parse={value => { return value; }}/>
                <TextInput source="secondSeatSupervisorHR"sx={{ width: "300px" }}parse={value => { return value; }}/>
                <TextInput source="thirdSeatSupervisor"sx={{ width: "300px" }} parse={value => { return value; }}/>
                <TextInput source="thirdSeatSupervisorHR"sx={{ width: "300px" }}parse={value => { return value; }}/>
                <TextInput source="fourthSeatSupervisor"sx={{ width: "300px" }}parse={value => { return value; }}/>
                <TextInput source="fourthSeatSupervisorHR"sx={{ width: "300px" }}parse={value => { return value; }}/>
            </SimpleForm>
        </Edit >)
    }
    return (
        <Edit {...props} transform={value => { return value; }} actions={<BackButton />} mutationMode="pessimistic" queryOptions={{ meta: { admin: true } }} mutationOptions={{ onSuccess, meta: { admin: true } }}>
            <SimpleForm toolbar={<TraineeEditToolbar />} sanitizeEmptyValues={false}>
                <ReferenceInput source="manualOverrideID" reference='departments' allowEmpty filter={{ departmentsByTypeAndName: { type: "Department" } }} perPage={1000}>
                    <SelectInput source="name" label="Manual Override Department" sx={{ width: "200px" }} allowEmpty parse={value => { return value; }} optionText={record => <DepartmentPlusSiteField record={record} />} />
                </ReferenceInput>
            </SimpleForm>
        </Edit >)
}

export const DepartmentPlusSiteField = ({ record }) => {
    const id = record?.siteID
    const { isLoading, data } = useGetOne('sites', { id });
    if (!isLoading && process.env.REACT_APP_MULTISITE == "1") {
        return record?.name + " - " + data?.name
    }
    return record?.name
}

export const TraineeEditUserView = (props) => (
    <Edit {...props} transform={value => { return value; }} redirect={'/'} actions={<BackButton />}>
        <SimpleForm toolbar={<TraineeEditToolbar />} sanitizeEmptyValues={false}>
            {process.env.REACT_APP_DESIRED_GRADUATION_SEAT_ON != "0" && "Please add in the practice area that is your most favoured practice area that you would like to work in as an NQ. If you are undecided on what this would be, please choose - Undecided."}
            {process.env.REACT_APP_DESIRED_GRADUATION_SEAT_ON != "0" && <ReferenceInput source="desiredGraduatingSeatID" reference='departments' allowEmpty filter={{ departmentsByTypeAndName: { type: "Department", filter:{ or: [{visibleToTrainees: {ne: false}}, {notDecidedIndicator: {eq: true}}] } }}} perPage={1000}>
                <SelectInput source="name" label="Desired Qualification Area" sx={{ width: "200px" }} allowEmpty parse={value => { return value; }} optionText={record => <DepartmentPlusSiteField record={record} />} />
            </ReferenceInput>}
        </SimpleForm>
    </Edit>
)


export const PostPagination = () => {
    const { page, hasPreviousPage, hasNextPage, setPage, setPerPage, perPage } = useListContext();

    return (
        <Box display="flex" alignItems="stretch" alignContent='space-between' mb={1}>
            <Box display="flex" alignItems="flex-end" mb={1} sx={{ flexGrow: 1 }}>
                {hasPreviousPage &&
                    <Button
                        key="previous"
                        onClick={() => setPage(page - 1)}
                        startIcon={<ChevronLeft />}
                    >
                        Previous
                    </Button>
                }</Box>

            <Box display="flex" alignItems="flex-end" mb={1} sx={{ flexGrow: 1, m: 1 }} justifyContent="flex-end">
                <Box display="flex" alignItems="flex-end" justifyContent="center" sx={{ alignItems: 'center' }}>
                    <Select value={perPage} size="small" onChange={event => { setPage(1); setPerPage(event.target.value) }}>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={150}>150</MenuItem>
                    </Select> <Typography sx={{ m: 1 }}>per Page</Typography>
                </Box>
                {hasNextPage &&
                    <Button
                        key="next"
                        onClick={() => setPage(page + 1)}
                        startIcon={<ChevronRight />}
                    >
                        Next
                    </Button>
                }

            </Box>
        </Box>
    );
}