/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://sc67sfd8u3.execute-api.eu-west-2.amazonaws.com/pinsents",
            "region": "eu-west-2"
        },
        {
            "name": "assignSeats",
            "endpoint": "https://774ri2l7y1.execute-api.eu-west-2.amazonaws.com/pinsents",
            "region": "eu-west-2"
        },
        {
            "name": "publishSeats",
            "endpoint": "https://ot42bd3l09.execute-api.eu-west-2.amazonaws.com/pinsents",
            "region": "eu-west-2"
        },
        {
            "name": "reminderEmail",
            "endpoint": "https://m79fli0fn7.execute-api.eu-west-2.amazonaws.com/pinsents",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://6zlm7yd6h5ajxde6npggvot5im.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:6259e0e7-b0d7-4a99-96bd-0816c603bd1d",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_AsoAf1UeG",
    "aws_user_pools_web_client_id": "2c2utnk4l2d68u64qqjddmtdgk",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "optiseatb36133a8b081486898e91560fcd6852c103247-pinsents",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
